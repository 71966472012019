import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import useLeadCapture from "../../../pages/UseLeadCapture";
import "./TalkToExpertBanner.css";
import reportsPageBanner from "../../../assets/Banners/reportsPageTalkToExpertBanner.svg";
import reportsPageBannerMob from "../../../assets/Banners/reportsPageTalkToExpertBannerMob.svg";
import reportsPageBuyReportBannerMob_Grade_3_4 from "../../../assets/Banners/reportsPageBuyReportBannerMob_Grade_3_4.svg";
import reportsPageBuyReportBannerMob_AllGrades from "../../../assets/Banners/reportsPageBuyReportBannerMob_AllGrades.svg";
import reportsPageBuyReportBannerMob_AllGrades_After24h from "../../../assets/Banners/reportsPageBuyReportBannerMob_AllGrades_After24h.svg";
import { getUserInfo } from "../../../store/auth/selectors";

export const TalkToExpertBanner = ({
  onBannerClick,
  timeLeft24,
  isBasicReport = false,
  showBuyReportBanner = true
}) => {
  const user = useSelector(getUserInfo);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { handelAlerbarLead } = useLeadCapture();

  const handleMobileBannerClick = useCallback(() => {
    if (currentIndex !== 0) {
      // Buy report banner clicked
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth"
      });

      handelAlerbarLead({
        source: "bottom_banner_report"
      });
    } else {
      // Talk to expert banner clicked
      handelAlerbarLead({
        source: "bottom_banner_tte"
      });
      onBannerClick();
    }
  }, [handelAlerbarLead, onBannerClick, currentIndex]);

  const getMobileBanners = useCallback(() => {
    if (!isBasicReport || !showBuyReportBanner) {
      return [reportsPageBannerMob];
    }

    if (user?.grade_id === 3 || user?.grade_id === 4) {
      return [reportsPageBannerMob, reportsPageBuyReportBannerMob_Grade_3_4];
    }

    // For other grades, show banners based on timeLeft24
    if (timeLeft24 <= 86400 && timeLeft24 > 0) {
      // Show discount banner within 24 hours
      return [reportsPageBannerMob, reportsPageBuyReportBannerMob_AllGrades];
    } else if (timeLeft24 === 0) {
      // Show regular price banner after 24 hours
      return [reportsPageBannerMob, reportsPageBuyReportBannerMob_AllGrades_After24h];
    }

    return [reportsPageBannerMob];
  }, [user?.grade_id, timeLeft24, isBasicReport, showBuyReportBanner]);

  const mobileBanners = getMobileBanners();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % mobileBanners.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [mobileBanners.length]);
  return (
    <>
      <div className="banner-column">
        <picture>
          <img src={reportsPageBanner} alt="Talk to Expert Banner" className="banner-image" onClick={onBannerClick} />
        </picture>
      </div>

      <div className="banner-trigger">
        <AnimatePresence mode="wait" initial={false}>
          <motion.img
            key={`banner-${currentIndex}-${mobileBanners[currentIndex]}`}
            src={mobileBanners[currentIndex]}
            alt="Talk to Expert Banner"
            className="banner-image"
            onClick={handleMobileBannerClick}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            transition={{ duration: 0.3 }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset }) => {
              if (offset.x < -50) {
                setCurrentIndex((prev) => (prev + 1) % mobileBanners.length);
              } else if (offset.x > 50) {
                setCurrentIndex((prev) => (prev === 0 ? mobileBanners.length - 1 : prev - 1));
              }
            }}
          />
        </AnimatePresence>
      </div>
    </>
  );
};
