import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetSchoolDetailsMutation } from "../../../store/ApiMutaion";
import useLeadCapture from "../../../pages/UseLeadCapture";
import { getDeviceAndLocationPayload } from "../../../utils/getDeviceDetails.utils";
import { getUserInfo } from "../../../store/auth/selectors";
import "../../../assets/css/talk-to-expert.css";
import useOutsideClick from "../../../hooks/useOutsideClick";
import talkToExpertPopUpLeftSideBanner from "../../../assets/Banners/talkToExpertPopUpLeftSideBanner.svg";
import talkToExpertSessionBooked from "../../../assets/gifs/talk_to_expert_session_booked.gif";
import talkToExpertContinueArrow from "../../../assets/svg/talk_to_expert_continue_arrow.svg";
import talkToExpertSelectedAttendee from "../../../assets/svg/talk_to_expert_selected.svg";
import talkToExpertUnselectedAttendee from "../../../assets/svg/talk_to_expert_unselected.svg";
import talkToExpertPopupCloseBtn from "../../../assets/svg/talk_to_expert_popup_close_btn.svg";

export const TalkToExpertModal = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deviceLocation, setDeviceLocation] = useState(null);

  const [getSchoolDetails] = useGetSchoolDetailsMutation();
  const { handelAlerbarLead, handleTalkToExpertLeadCapture } = useLeadCapture();
  const [schoolName, setSchoolName] = useState("");
  const user = useSelector(getUserInfo);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    if (!isOpen) return;

    const params = {
      source: "talk_to_expert"
    };
    handelAlerbarLead(params);
  }, [isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const location = await getDeviceAndLocationPayload();
        setDeviceLocation(location);

        if (user?.school_id) {
          try {
            const schoolResponse = await getSchoolDetails(user.school_id).unwrap();
            setSchoolName(schoolResponse?.data?.school_name || "");
          } catch (schoolErr) {
            console.error("Error fetching school details:", schoolErr);
            setError("Failed to fetch school details");
          }
        }
      } catch (err) {
        console.error("Error fetching device location:", err);
      }
    };
    fetchData();
  }, [user?.school_id, getSchoolDetails]);

  const close = () => {
    onClose();
    setCurrentStep(1);
    setSelectedAttendees([]);
    setSelectedDate("");
    setSelectedTime("");
    setError(null);
  };

  const handleFinishBooking = async () => {
    try {
      setIsLoading(true);
      setError(null);

      await handleTalkToExpertLeadCapture({
        user,
        selectedDate,
        selectedTime,
        selectedAttendees,
        schoolName,
        deviceLocation
      });
      setCurrentStep(3);
    } catch (err) {
      setError("Failed to book session. Please try again.");
      console.error("Error booking session:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const containerRef = useOutsideClick(close);

  const handleAttendeeChange = (attendee) => {
    setSelectedAttendees((prev) => {
      if (prev.includes(attendee)) {
        return prev.filter((a) => a !== attendee);
      }
      return [...prev, attendee];
    });
  };

  const renderStepIndicator = () => (
    <div className="talk-to-expert-steps">
      <div className={`talk-to-expert-step ${currentStep >= 1 ? "active" : ""}`}>
        <span className="talk-to-expert-step-number">1</span>
        <span className="talk-to-expert-step-text">select slot</span>
      </div>
      <div className={`talk-to-expert-step ${currentStep >= 2 ? "active" : ""}`}>
        <span className="talk-to-expert-step-number">2</span>
        <span className="talk-to-expert-step-text">personalise session</span>
      </div>
      <div className={`talk-to-expert-step ${currentStep >= 3 ? "active" : ""}`}>
        <span className="talk-to-expert-step-number">3</span>
        <span className="talk-to-expert-step-text">session booked</span>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="talk-to-expert-content">
            <h2>when would you like to speak to the counsellor?</h2>
            <p className="talk-to-expert-subtitle">please pick your suitable date and time for the call</p>
            <div className="talk-to-expert-date-selection">
              <h3>select date</h3>
              <div className="talk-to-expert-date-options">
                {Array.from({ length: 5 }, (_, index) => {
                  const date = new Date();
                  date.setDate(date.getDate() + index + 1); // Start from tomorrow

                  const month = date.toLocaleDateString("en-US", { month: "short" }).toLowerCase();
                  const day = date.getDate();
                  const formattedDate = `${month} ${day}`; // Format expected by useLeadCapture's formatDateTime: "jan 15"
                  const isMonday = date.getDay() === 1;

                  return (
                    <label key={formattedDate} className={`talk-to-expert-date-option ${isMonday ? "disabled" : ""}`}>
                      <input
                        type="radio"
                        name="date"
                        value={formattedDate}
                        checked={selectedDate === formattedDate}
                        onChange={(e) => !isMonday && setSelectedDate(e.target.value)}
                        disabled={isMonday}
                      />
                      <div className="talk-to-expert-date-label">
                        <span className="talk-to-expert-day">
                          {date.toLocaleDateString("en-US", { weekday: "short" }).toLowerCase()}
                        </span>
                        <span className="talk-to-expert-date">{`${month} ${day}`}</span>
                        {isMonday && <span className="slots-full-text">Slots full</span>}
                      </div>
                    </label>
                  );
                })}
              </div>
              <h3>select time</h3>
              <div className="talk-to-expert-time-options">
                {["12 pm", "2 pm", "4 pm", "6 pm", "7 pm"].map((time) => (
                  <label key={time} className="talk-to-expert-time-option">
                    <input
                      type="radio"
                      name="time"
                      value={time}
                      checked={selectedTime === time}
                      onChange={(e) => setSelectedTime(e.target.value)}
                    />
                    <span>{time}</span>
                  </label>
                ))}
              </div>
              <button
                className="talk-to-expert-continue"
                onClick={() => setCurrentStep(2)}
                disabled={!selectedDate || !selectedTime}
              >
                continue
                <img src={talkToExpertContinueArrow} alt="continue" className="talk-to-expert-arrow" />
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="talk-to-expert-content">
            <h2>who will be attending the call?</h2>
            <p className="talk-to-expert-subtitle">
              this journey is a group effort! we would love to include your family in the call!
            </p>
            <div className="talk-to-expert-attendees">
              {["father", "mother", "guardian", "not sure", "the student"].map((attendee) => (
                <label
                  key={attendee}
                  className={`talk-to-expert-attendee ${
                    selectedAttendees.includes(attendee) ? "attendee-selected" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selectedAttendees.includes(attendee)}
                    onChange={() => handleAttendeeChange(attendee)}
                  />
                  {selectedAttendees.includes(attendee) ? (
                    <img src={talkToExpertSelectedAttendee} />
                  ) : (
                    <img src={talkToExpertUnselectedAttendee} />
                  )}
                  <span>{attendee}</span>
                </label>
              ))}
            </div>
            <button
              className="talk-to-expert-finish"
              onClick={handleFinishBooking}
              disabled={selectedAttendees.length === 0 || isLoading}
            >
              {isLoading ? "booking..." : error ? "" : "finish booking"}
              {error && <div className="talk-to-expert-error">{error}</div>}
              <img src={talkToExpertContinueArrow} alt="continue" className="talk-to-expert-arrow" />
            </button>
          </div>
        );
      case 3:
        return (
          <div className="talk-to-expert-content success-content">
            <img src={talkToExpertSessionBooked} alt="Session Booked" className="success-gif" />
            <h2>Super! session has been booked!</h2>
          </div>
        );
      default:
        return null;
    }
  };

  return isOpen ? (
    <div className={`talk-to-expert-wrapper ${currentStep === 3 ? "success-step" : ""}`}>
      <div className={`talk-to-expert-container ${currentStep === 3 ? "success-container" : ""}`} ref={containerRef}>
        <button className="talk-to-expert-close" onClick={close}>
          <img src={talkToExpertPopupCloseBtn} alt="Close" />
        </button>

        {currentStep !== 3 ? (
          <>
            {/* Left side with banner */}
            <div className="talk-to-expert-left">
              <img src={talkToExpertPopUpLeftSideBanner} alt="Talk to Expert Banner" />
            </div>

            {/* Right side with form */}
            <div className="talk-to-expert-right">
              {renderStepIndicator()}
              {renderContent()}
            </div>
          </>
        ) : (
          <div className="talk-to-expert-success">
            {renderStepIndicator()}
            {renderContent()}
          </div>
        )}
      </div>
    </div>
  ) : null;
};
