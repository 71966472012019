export const CONFIG_VARS = Object.freeze({
  // schoolLoginUrl: "https://poc.inmobiusinfinitylearn.com/login", // prod replicaURL
  // schoolLoginUrl: "https://adminpreprod.inmobiusinfinitylearn.com/login", // pre-prod URL
  // schoolLoginUrl: "https://inmobiusstaging.devinfinitylearn.in/login", // staging URL
  schoolLoginUrl: "https://admin.inmobiusinfinitylearn.com/login", // Prod URL

  environment: "production", //prod
  // environment: "prepord", // prepord

  baseUrl: {
    // 1: "https://adminpreprod.inmobiusinfinitylearn.com/api" // pre-prod URL
    //1: "https://inmobiusstaging.devinfinitylearn.in/api" // staging URL
    1: "https://admin.inmobiusinfinitylearn.com/api" // prod URL
    //1: "https://poc.inmobiusinfinitylearn.com/api" //prod replica
  },
  jobUrl: {
    // 1: "https://inmobiusjob.devinfinitylearn.in/api" // pre-prod URL
    // 1: "https://inmobiusstaging.devinfinitylearn.in/api", // staging URL
    1: "https://inmobiusjob.infinitylearn.com/api" // prod URL
    //1: "https://poc.inmobiusinfinitylearn.com/api" //prod replica
  },
  //phoneBaseUrl: "https://gatewaystaging.devinfinitylearn.in/api", // stating URL
  phoneBaseUrl: "https://gatewayapiinmobius.infinitylearn.com/api", // prod URL
  // phoneBaseUrl: "https://gatewayapi.devinfinitylearn.in/api", //preprod
  //phoneBaseUrl: "https://pocgateway.inmobiusinfinitylearn.com/api", //prodreplica

  // otpBaseUrl: "https://comservice.devinfinitylearn.in/api", //preprod
  otpBaseUrl: "https://comservice.infinitylearn.com/api", //prod

  //scoreViewreportUrl: "https://scorepoc.devinfinitylearn.in/#", // poc
  // scoreViewreportUrl: "https://scorepreprod.devinfinitylearn.in/#", //prepord
  scoreViewreportUrl: "https://scoreservice.infinitylearn.com/#", //pord
  //scoreViewreportUrl: "https://testservice.devinfinitylearn.in/#", //staging

  certficateUrl: "https://certificates-reports.s3.ap-south-1.amazonaws.com/certificate", //prod
  // certficateUrl: " https://certificates-and-reports.s3.ap-south-1.amazonaws.com/certificate", //preprod

  // LsqUrl: "https://lsqservice.devinfinitylearn.in/api", //prepord
  LsqUrl: "https://lsqservice.infinitylearn.com/api", //prod

  // report_link: "https://inmobiusreports.devinfinitylearn.in/", //prepord
  report_link: "https://inmobiusreports.infinitylearn.com/", //prod

  // oms_url: "https://omsstagingapi.devinfinitylearn.in/", // staging
  // oms_url: "https://omsapi.devinfinitylearn.in/", // prepord
  oms_url: "https://omsapi.infinitylearn.com/", // prod

  // razorPayKey: "rzp_test_12ntRUqV41EEBk", //prepord
  razorPayKey: "rzp_live_NoGaIDZ74lwGZa", //prod

  // packageMapping: {
  //   scorePrelims: "301",
  //   "scoreSemi-Finals": "302",
  //   scoreFinals: "303",
  //   iemsatPrelims: "301",
  //   "iemsatSemi-Finals": "",
  //   iemsatFinals: "303",
  //   hotsolympiadPrelims: "301",
  //   "hotsolympiadSemi-Finals": "302",
  //   hotsolympiadFinals: "303",
  //   matholympiadPrelims: "301",
  //   "matholympiadSemi-Finals": "302",
  //   matholympiadFinals: "303"
  // }, //prepord

  packageMapping: {
    scorePrelims: "1236",
    "scoreSemi-Finals": "1237",
    scoreFinals: "1238",
    iemsatPrelims: "1241",
    "iemsatSemi-Finals": "",
    iemsatFinals: "",
    hotsolympiadPrelims: "1239",
    "hotsolympiadSemi-Finals": "1305",
    hotsolympiadFinals: "1317",
    matholympiadPrelims: "1240",
    "matholympiadSemi-Finals": "1319",
    matholympiadFinals: "1320"
  }, //prod

  apiVersion: "v1",
  headers: {
    ["product-id"]: "1",
    ["tenant-id"]: "1"
  },
  router: {
    1: "school",
    2: "user",
    3: "teacher"
  },
  countryApiKey: "a1JiWnJrVVp3YmxheTdybjFtbDVId2xjOU1UYUZ4dUZHMVNBMk5pZA=="
});
